import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import gstyles from '@vl/gstyles';
import { FormItem, Select } from 'formik-antd';
import CustomInput from '@uz/unitz-components-web/CustomInput';

const ToolSelectSetting = ({ name, label, data, title, note, placeholder }) => {
  return (
    <DIV className="component">
      <div className="bg-white500 shadow-md rounded-lg w-full">
        <div className="p-6">
          <div className="text-main font-semibold text-base">{title && title}</div>
        </div>
        <div className="border-b" />
        <div className="p-6 flex w-full flex-col">
          <div className="text-sub font-normal text-sm mb-4">{note && note}</div>
          <CustomInput>
            <FormItem name={name} label={<div className="text-sub text-sm font-semibold">{label && label}</div>}>
              <Select
                showSearch
                size="large"
                name={name}
                suffixIcon={gstyles.icons({
                  name: 'arrow-down3',
                  size: 24,
                  fill: gstyles.colors.sub,
                  className: '-mt-2 -ml-2',
                })}
                placeholder={placeholder ? placeholder : ''}
              >
                {_.map(data, (item, index) => (
                  <Select.Option value={!!item.value ? item.value : `value_${name}_${index}`} key={item.id}>
                    {item.text}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </CustomInput>
        </div>
      </div>
    </DIV>
  );
};

export default ToolSelectSetting;
