import { bindings, hook } from '@vl/redata';
import useLoadingModel from '@vl/hooks/useLoadingModel';
import usePromiseSource from '@vl/hooks/usePromiseSource';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useRoute from '@vl/hooks/useGbRouteDe';
import useObservableSource from '@vl/hooks/useObservableSource';
import useSubjectSource from '@vl/hooks/useSubjectSource';
import { getClient, gql } from '@vl/mod-clients/hasuraApp';
import fbFnsClient from '@vl/mod-clients/fibGatsbyFns';
import React from 'react';

const INIT_STATE = {};

const bindData = bindings({
  loader: {
    rules: [
      [
        'data',
        {
          data: {
            loadingModel: hook(useLoadingModel(() => ({ fetchData: true }))),
            account_domain: hook((ctx) => {
              const account_id = ctx.apply('accountModel.getAccountId');
              const sub = useObservableSource(
                async () => {
                  try {
                    if (!account_id) return null;
                    const client = await getClient();
                    const data = await client.subscribe(
                      gql`
                        subscription subscription(
                          $where: b2b_account_domain_bool_exp = {}
                        ) {
                          b2b_account_domain(where: $where) {
                            id
                            domain
                            zone_name
                            info
                            account {
                              id
                              slug
                            }
                          }
                        }
                      `,
                      { where: { account_id: { _eq: account_id } } }
                    );
                    return { observe: () => data };
                  } catch (err) {
                    console.log('error', err);
                  }
                },
                null,
                [account_id]
              );
              const data = useSubjectSource(sub, INIT_STATE);
              if (data !== INIT_STATE) {
                ctx.apply('loadingModel.clearLoading', 'fetchData');
              }
              const rtn = _.get(data, 'data.b2b_account_domain.0', {});
              return rtn;
            }),
          },
        },
      ],
    ],
  },
  settingsComponent: {
    rules: [
      [
        'data',
        {
          data: {
            form: hook((ctx) => {
              const user_id = ctx.apply('currentUserModel.getUserId');
              const account_id = ctx.apply('accountModel.getAccountId');
              const route = useRoute();
              const account_domain = ctx.get('account_domain');
              const account_domain_info = ctx.get('account_domain.info');
              const zone_name = _.get(account_domain, 'zone_name', 'myunitz.com');
              const initialValues = {
                domain: _.get(account_domain_info, 'hostname'),
                // ssl_status: 'pending_validation',
                ssl_status: _.get(account_domain_info, 'ssl.status', ''),
                ssl_validation_name: _.get(account_domain_info, 'ssl.validation_records.0.txt_name', ''),
                ssl_validation_value: _.get(account_domain_info, 'ssl.validation_records.0.txt_value', ''),
                ownership_status: _.get(account_domain_info, 'status', ''),
                ownership_validation_name: _.get(account_domain_info, 'ownership_verification.name', ''),
                ownership_validation_value: _.get(account_domain_info, 'ownership_verification.value', ''),
                cname_record_name: _.get(account_domain_info, 'hostname'),
                cname_record_value: `${_.get(account_domain, 'account.slug', '')}.${zone_name}.`,
              };
              const form = useFormik({
                enableReinitialize: true,
                initialValues,
                onSubmit: async (values, actions) => {
                  try {
                    const domain = _.get(values, 'domain');
                    await fbFnsClient.getClient().post('toolCourse-configAccountDomain', {
                      user_id,
                      account_id,
                      domain,
                    });
                  } catch (error) {
                    console.log(error);
                    if (['validation-failed'].includes(error.code)) {
                      form.setFieldError('first_name', ctx.apply('i18n.t', 'ZoomToolError.default'));
                    }
                    // actions.setStatus({
                    //   error,
                    // });
                  }
                },
                validationSchema: Yup.object().shape({
                  domain: Yup.string().domain().required(),
                }),
              });
              _.assign(form, {
                canSubmit: form.dirty && form.isValid,
              });
              return form;
            }),
            handleRefreshAccountDomain: hook((ctx) => {
              const user_id = ctx.apply('currentUserModel.getUserId');
              const account_id = ctx.apply('accountModel.getAccountId');
              const ownership_status = ctx.get('account_domain.info.status');
              const handler = async () => {
                try {
                  await fbFnsClient.getClient().post('toolCourse-refreshAccountDomain', {
                    user_id,
                    account_id,
                  });
                } catch (error) {
                  console.log(error);
                }
              };
              React.useEffect(() => {
                if (ownership_status && ownership_status !== 'active') {
                  handler();
                }
              }, [account_id, ownership_status]);
              return handler;
            }),
            handleRemoveAccountDomain: hook((ctx) => {
              const user_id = ctx.apply('currentUserModel.getUserId');
              const account_id = ctx.apply('accountModel.getAccountId');
              const handler = async () => {
                try {
                  await fbFnsClient.getClient().post('toolCourse-removeAccountDomain', {
                    user_id,
                    account_id,
                  });
                } catch (error) {
                  console.log(error);
                }
              };
              return handler;
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
