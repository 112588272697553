import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import { UnAuthRedirect } from '@uz/unitz-tool-components/AppRedirect';
import _ from 'lodash';
import FormEditMyAccount from '@uz/unitz-tool-components/FormEditMyAccount';

const Index = () => {
  return (
    <DIV className="pageContainer">
      <UnAuthRedirect to={ctx.apply('authModel.generateLoginRedirectUrl')}>
        <div className="p-4 h-full">{!_.isEmpty(ctx.get('@item')) && <FormEditMyAccount />}</div>
      </UnAuthRedirect>
    </DIV>
  );
};

export default displayName(Index);
