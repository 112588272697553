import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
import useRoute from '@vl/hooks/useGbRouteDe';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getClient, gql } from '@vl/mod-clients/hasuraApp';
import { notification } from 'antd';
import timezones from '@vl/mod-utils/time/timezones.json';
import useLoadingModel from '@vl/hooks/useLoadingModel';
import usePromiseSource from '@vl/hooks/usePromiseSource';
import { LOCALE_CONFIG } from '@uz/mod-translations/constants';
import { getLocaleConfigByLang } from '@uz/mod-translations/utils-biz';

const bindData = bindings({
  loader: {
    rules: [
      [
        'data',
        {
          data: {
            loadingModel: hook(useLoadingModel(() => ({ fetchData: true }))),
            currentLang: hook((ctx) => {
              const user_id = ctx.apply('currentUserModel.getUserId');
              const lang = usePromiseSource(
                async () => {
                  try {
                    const lang = await ctx.apply('currentUserModel.getLang');
                    ctx.apply('loadingModel.clearLoading', 'fetchData');
                    const found = _.find(LOCALE_CONFIG, (item) => _.includes(item.lang, lang));
                    return _.get(found, 'lang') || lang;
                  } catch (err) {
                    console.log('error', err);
                  }
                },
                null,
                [user_id]
              );
              return lang;
            }),
            currentTz: hook((ctx) => {
              const user_id = ctx.apply('currentUserModel.getUserId');
              const tz = usePromiseSource(
                async () => {
                  try {
                    const tz = await ctx.apply('currentUserModel.getTz');
                    ctx.apply('loadingModel.clearLoading', 'fetchData');
                    const found = _.find(timezones, (item) => _.includes(item.utc, tz));
                    return _.get(found, 'value') || tz;
                  } catch (err) {
                    console.log('error', err);
                  }
                },
                null,
                [user_id]
              );
              return tz;
            }),
          },
        },
      ],
    ],
  },
  component: {
    rules: [
      [
        'data',
        {
          data: {
            currentLanguage: hook((ctx) => {
              const route = useRoute();
              const pageContext = route.getPageContext();
              const lang = _.get(pageContext, 'lang', 'de');
              return lang;
            }),

            form: hook((ctx) => {
              const data = ctx.get('@item.b2b_member_profile.0');
              const user_id = ctx.apply('currentUserModel.getUserId');
              const account_id = ctx.apply('accountModel.getAccountId');
              const route = useRoute();
              const form = useFormik({
                initialValues: {
                  first_name: _.get(data, 'first_name', ''),
                  last_name: _.get(data, 'last_name', ''),
                  date_of_birth: _.get(data, 'date_of_birth') ? _.get(data, 'date_of_birth') : '',
                  email: _.get(data, 'email') || ctx.apply('authModel.getUserEmail'),
                  phone: _.get(data, 'phone'),
                  time_zone: ctx.get('currentTz'),
                  language: ctx.get('currentLang'),
                  region: '',
                  calendar: '',
                  font_size: '',
                  current_password: '',
                  new_password: '',
                  confirm_password: '',
                },

                onSubmit: async (values, actions) => {
                  try {
                    const info_payload = !!_.get(values, 'date_of_birth', '')
                      ? {
                          first_name: _.get(values, 'first_name', ''),
                          last_name: _.get(values, 'last_name', ''),
                          date_of_birth: _.get(values, 'date_of_birth', ''),
                          email: _.get(values, 'email'),
                          phone: _.get(values, 'phone'),
                        }
                      : {
                          first_name: _.get(values, 'first_name', ''),
                          last_name: _.get(values, 'last_name', ''),
                          email: _.get(values, 'email'),
                          phone: _.get(values, 'phone'),
                        };
                    const tz = _.get(
                      _.find(timezones, ({ value }) => value === values.time_zone),
                      'utc.0'
                    );

                    await ctx.apply('currentUserModel.updateTz', tz);

                    const client = await getClient();
                    const res = await client.request(
                      gql`
                        mutation MyAccount($info_payload: b2b_member_profile_set_input = {}) {
                          update_b2b_member_profile(where: {account_id: {_eq: "${account_id}"}, user_id: {_eq: "${user_id}"}}, _set: $info_payload) {
                            returning{
                              account_id
                              user_id
                            }
                          }
                        }
                      `,
                      {
                        info_payload,
                      }
                    );

                    if (res) {
                      await notification.success({
                        placement: 'bottomRight',
                        message: ctx.apply('i18n.t', 'ZoomToolSettings.Notification.success'),
                      });
                    }
                    if (!!values.language) {
                      await ctx.apply('currentUserModel.updateLanguage', values.language);

                      const localeConfig = getLocaleConfigByLang(values.language);
                      await localeConfig.setCurrent();
                      route.navigate(localeConfig.withLangPrefix());
                    }
                  } catch (error) {
                    console.log(error);
                    if (['validation-failed'].includes(error.code)) {
                      form.setFieldError('first_name', ctx.apply('i18n.t', 'ZoomToolError.default'));
                    }
                    // actions.setStatus({
                    //   error,
                    // });
                  }
                },

                validationSchema: Yup.object().shape({}),
              });

              _.assign(form, {
                canSubmit: form.dirty && form.isValid,
                selectOptions: {
                  language: (() => {
                    return _.map(LOCALE_CONFIG, (item, index) => {
                      const lang = _.get(item, 'lang', '');
                      return {
                        id: index,
                        text: ctx.apply('i18n.t', `SettingLanguage.${lang}`),
                        value: lang,
                      };
                    });
                  })(),
                  region: (() => {
                    return [
                      {
                        id: 'vietnam',
                        text: 'Viet Nam',
                      },
                      {
                        id: 'english',
                        text: 'English',
                      },
                      {
                        id: 'germany',
                        text: 'Germany',
                      },
                    ];
                  })(),
                  calendar: (() => {
                    return [
                      {
                        id: 'gregorian',
                        text: 'Gregorian',
                      },
                      {
                        id: 'japanese',
                        text: 'Japanese',
                      },
                      {
                        id: 'buddhist',
                        text: 'Buddhist',
                      },
                    ];
                  })(),
                  timezone: (() => {
                    const items = _.map(timezones, (timezone, index) => {
                      return {
                        id: `id_${index}`,
                        text: timezone.text,
                        value: _.get(timezone, 'value'),
                      };
                    });
                    return items;
                  })(),
                  font_size: (() => {
                    return [
                      {
                        id: '1',
                        text: '16',
                      },
                      {
                        id: '2',
                        text: '20',
                      },
                      {
                        id: '3',
                        text: '24',
                      },
                    ];
                  })(),
                },
              });
              return form;
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
