import { bindings, hook } from '@vl/redata';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {},
        },
      ],
    ],
  },
});

export default bindData;
