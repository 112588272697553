import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { FormItem, Form } from 'formik-antd';
import InputText from '@uz/unitz-components-web/InputText';
import CustomInput from '@uz/unitz-components-web/CustomInput';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import LoadingCheckWrapper from '@uz/unitz-components-web/LoadingCheckWrapper';
import Button from '@uz/unitz-components-web/Button';
import { Row, Col } from 'antd';
import { renderPropsComposer } from '@vl/mod-utils/PropsComposer';

const layouts = {
  labelCol: { span: 24 },
  labelAlign: 'left',
};

const View8 = () => {
  return (
    <LoadingCheckWrapper>
      <DIV className="settingsComponent">
        <FormProvider form={ctx.get('form')}>
          <Form {...layouts} className="my-account-form">
            <div className="bg-white500 shadow-md rounded-lg">
              <div className="p-6">
                <div className="text-main font-semibold text-base">{ctx.apply('i18n.rt', 'Domain Settings')}</div>
              </div>
              <div className="border-b" />
              <div className="flex flex-col space-y-4 p-6 mb-6">
                <CustomInput>
                  <FormItem
                    name="domain"
                    label={
                      <div className='flex space-x-2 items-center'>
                        <div className="text-sub text-sm font-semibold">
                          {ctx.apply('i18n.rt', 'Domain')}
                        </div>
                      </div>
                    }
                  >
                    <InputText name="domain" />
                    <div className='flex space-x-1'>
                      <Button
                        size="small"
                        type="link"
                        loading={ctx.get('form.isSubmitting')}
                        onClick={() => ctx.apply('form.submitForm')}
                        disabled={!ctx.get('form.canSubmit')}
                      >
                        {ctx.apply('i18n.t', 'Setting.Language.save')}
                      </Button>
                      <Button
                        size="small"
                        type="link"
                        onClick={ctx.get('handleRemoveAccountDomain')}
                        disabled={!ctx.get('form.values.domain')}
                      >
                        {ctx.apply('i18n.rt', 'Remove')}
                      </Button>
                    </div>
                  </FormItem>
                </CustomInput>
                {
                  renderPropsComposer(
                    {
                      matcher: () => {
                        return (
                          (ctx.get('form.values.ownership_status') !== 'active' && !!ctx.get('form.values.ownership_status')) ||
                          (ctx.get('form.values.ssl_status') !== 'active' && !!ctx.get('form.values.ssl_status'))
                        );
                      },
                      render: () => (
                        <div>
                          {ctx.apply('i18n.rt', 'As soon as the TXT listed in the table below is in place, the SSL certificates will be issued and deployed. Please allow a few minutes after the TXT record has been added before attempting to connect via HTTPS.')}
                          <Button
                            size="small"
                            type="link"
                            onClick={ctx.get('handleRefreshAccountDomain')}
                          >
                            {ctx.apply('i18n.rt', 'Refresh')}
                          </Button>
                        </div>
                      ),
                    },
                  )()
                }
                {
                  renderPropsComposer(
                    {
                      matcher: () => ctx.get('form.values.ssl_status') === 'active',
                      render: () => (
                        <div>{ctx.apply('i18n.rt', 'The certificate has been successfully issued and deployed.')}</div>
                      ),
                    },
                    {
                      matcher: () => !!ctx.get('form.values.ssl_validation_name'),
                      render: () => (
                        <Row gutter={8}>
                          <Col xs={24} md={12}>
                            <CustomInput>
                              <FormItem
                                name="ssl_validation_name"
                                label={
                                  <div className="text-sub text-sm font-semibold">
                                    {ctx.apply('i18n.rt', 'Certificate validation TXT name')}
                                  </div>
                                }
                              >
                                <InputText name="ssl_validation_name" disabled canCopy />
                              </FormItem>
                            </CustomInput>
                          </Col>
                          <Col xs={24} md={12}>
                            <CustomInput>
                              <FormItem
                                name="ssl_validation_value"
                                label={
                                  <div className="text-sub text-sm font-semibold">
                                    {ctx.apply('i18n.rt', 'Certificate validation TXT value')}
                                  </div>
                                }
                              >
                                <InputText name="ssl_validation_value" disabled canCopy />
                              </FormItem>
                            </CustomInput>
                          </Col>
                        </Row>
                      ),
                    },
                  )()
                }
                {
                  renderPropsComposer(
                    {
                      matcher: () => ctx.get('form.values.ownership_status') === 'active',
                      render: () => (
                        <div>{ctx.apply('i18n.rt', 'The domain has been successfully deployed.')}</div>
                      ),
                    },
                    {
                      matcher: () => !!ctx.get('form.values.ownership_validation_name'),
                      render: () => (
                        <Row gutter={8}>
                          <Col xs={24} md={12}>
                            <CustomInput>
                              <FormItem
                                name="ownership_validation_name"
                                label={
                                  <div className="text-sub text-sm font-semibold">
                                    {ctx.apply('i18n.rt', 'Hostname validation TXT name')}
                                  </div>
                                }
                              >
                                <InputText name="ownership_validation_name" disabled canCopy />
                              </FormItem>
                            </CustomInput>
                          </Col>
                          <Col xs={24} md={12}>
                            <CustomInput>
                              <FormItem
                                name="ownership_validation_value"
                                label={
                                  <div className="text-sub text-sm font-semibold">
                                    {ctx.apply('i18n.rt', 'Hostname validation TXT value')}
                                  </div>
                                }
                              >
                                <InputText name="ownership_validation_value" disabled canCopy />
                              </FormItem>
                            </CustomInput>
                          </Col>
                        </Row>
                      ),
                    },
                  )()
                }
                {
                  renderPropsComposer(
                    {
                      matcher: () => !!ctx.get('form.values.ownership_status'),
                      render: () => (
                        <CustomInput>
                          <FormItem
                            name="cname"
                            label={
                              <div className="text-sub text-sm font-semibold">
                                {ctx.apply('i18n.rt', 'CNAME record')}
                              </div>
                            }
                          >
                            <div className='flex items-center'>
                              <div className='flex-1'>
                                <InputText name="cname_record_name" disabled canCopy />
                              </div>
                              <div className='px-2'>to</div>
                              <div className='flex-1'>
                                <InputText name="cname_record_value" disabled canCopy />
                              </div>
                            </div>
                          </FormItem>
                        </CustomInput>
                      ),
                    },
                  )()
                }
              </div>
            </div>
          </Form>
        </FormProvider>
      </DIV>
    </LoadingCheckWrapper>
  );
};

export default displayName(View8);
