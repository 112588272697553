import React from 'react';
import { bindings, hook } from '@vl/redata';
import useLoadingModel from '@vl/hooks/useLoadingModel';
import usePromiseSource from '@vl/hooks/usePromiseSource';
import { getClient, gql } from '@vl/mod-clients/hasuraApp';
import _ from 'lodash';

const bindData = bindings({
  pageContainer: {
    rules: [
      [
        'data',
        {
          data: {
            loadingModel: hook(useLoadingModel(() => ({ fetchData: true }))),

            '@item': hook((ctx) => {
              const user_id = ctx.apply('authModel.getTargetProfileId');
              const account_id = ctx.apply('accountModel.getAccountId');
              const condition = {
                where: {
                  user_id: { _eq: user_id },
                  account_id: { _eq: account_id },
                },
              };
              const data = usePromiseSource(
                async () => {
                  try {
                    if (!account_id) return null;
                    const client = await getClient();
                    const user_data = await client.request(
                      gql`
                        query GetInformation($where: b2b_member_profile_bool_exp = {}) {
                          b2b_member_profile(where: $where) {
                            id
                            first_name
                            last_name
                            phone
                            email
                            date_of_birth
                          }
                        }
                      `,
                      { ...condition }
                    );
                    return user_data;
                  } catch (err) {
                    console.log('error', err);
                  }
                },
                null,
                [user_id, account_id]
              );

              return data;
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
