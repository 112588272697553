import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import gstyles from '@vl/gstyles';
import styled from 'styled-components';
import { FormItem, Select } from 'formik-antd';

const CustomInput = styled.div`
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-select {
    &:not(.ant-select-customize-input) {
      .ant-select-selector {
        border-radius: 8px;
        border-color: ${gstyles.colors.divider};
      }
    }
  }
`;

const View9 = () => {
  return (
    <DIV className="component">
      <div className="bg-white500 shadow-md rounded-lg">
        <div className="p-6">
          <div className="text-main font-semibold text-base">{ctx.apply('i18n.t', 'ZoomToolMyAccount.font_size')}</div>
        </div>
        <div className="border-b" />
        <div className="p-6 flex flex-col">
          <div className="text-sub font-normal text-sm mb-4">
            {ctx.apply('i18n.t', 'ZoomToolMyAccount.font_size_note')}
          </div>
          <CustomInput>
            <FormItem
              wrapperCol={{ span: 2 }}
              label={
                <div className="text-sub text-sm font-semibold">
                  {ctx.apply('i18n.t', 'ZoomToolMyAccount.font_size_label')}
                </div>
              }
              name="font_size"
            >
              <Select
                showSearch
                size="large"
                name="font_size"
                suffixIcon={gstyles.icons({
                  name: 'arrow-down3',
                  size: 24,
                  fill: gstyles.colors.sub,
                  className: '-mt-2 -ml-2',
                })}
                // placeholder={ctx.apply('i18n.t', `SettingLanguage.${ctx.get('currentLanguage')}`)}
              >
                {_.map(ctx.get('form.selectOptions.font_size'), (item) => (
                  <Select.Option value={item.lang} key={item.id}>
                    {item.text}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </CustomInput>
          {/* <div className="ml-3 text-sub font-normal text-sm">
            {ctx.apply('i18n.t', 'ZoomToolMyAccount.font_size_unit')}
          </div> */}
        </div>
      </div>
    </DIV>
  );
};

export default View9;
