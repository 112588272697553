import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { FormItem } from 'formik-antd';
import InputText from '@uz/unitz-components-web/InputText';
import CustomInput from '@uz/unitz-components-web/CustomInput';

const View8 = () => {
  return (
    <DIV className="component">
      <div className="bg-white500 shadow-md rounded-lg">
        <div className="p-6">
          <div className="text-main font-semibold text-base">{ctx.apply('i18n.t', 'ProfileUser.changePassword')}</div>
        </div>
        <div className="border-b" />
        <div className="flex flex-col space-y-4 p-6 mb-6">
          <CustomInput>
            <FormItem
              name="password"
              label={
                <div className="text-sub text-sm font-semibold">
                  {ctx.apply('i18n.t', 'ProfileUser.currentPasswordLabel')}
                </div>
              }
            >
              <InputText name="phone_number" type="password" />
            </FormItem>
          </CustomInput>

          <CustomInput>
            <FormItem
              name="new_password"
              label={
                <div className="text-sub text-sm font-semibold">
                  {ctx.apply('i18n.t', 'ProfileUser.newPasswordLabel')}
                </div>
              }
            >
              <InputText name="new_password" type="password" />
            </FormItem>
          </CustomInput>

          <CustomInput>
            <FormItem
              name="confirm_password"
              label={
                <div className="text-sub text-sm font-semibold">
                  {ctx.apply('i18n.t', 'ProfileUser.confirmPasswordLabel')}
                </div>
              }
            >
              <InputText name="confirm_password" type="password" />
            </FormItem>
          </CustomInput>
        </div>
      </div>
    </DIV>
  );
};

export default displayName(View8);
